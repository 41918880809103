import { Component, Mixins } from 'vue-property-decorator'
import { Account } from '@/api'
import { Toast } from '@/mixins'
import { UserModule } from '@/store/modules'

@Component
export default class Discord extends Mixins(Toast) {
  @UserModule.State currentUser
  @UserModule.Action getUser

  get connectedToDiscord() {
    const { discordAccount } = this.currentUser

    return discordAccount !== null && typeof(discordAccount) === 'object'
  }

  get discordCurrentUserIdentity() {
    return this.connectedToDiscord ? this.currentUser.discordAccount.displayName : 'Not linked'
  }

  getDiscordIdentity(user) {
    const { discordAccount } = user
  
    return discordAccount ? discordAccount.displayName : 'Not linked'
  }

  openDiscordLink() {
    window.open(process.env.VUE_APP_DISCORD_OAUTH_URL)
  }

  async checkIfDiscordIsLinked() {
    const code = new URLSearchParams(window.location.search).get('code')

    if (code) {
      try {
        const connection = await Account.connectToDiscord({ code })

        if (connection.status === 500) {
          throw new Error()
        }

        this.showSuccessfullyDiscordAccountLinked()
        await this.getUser()
      } catch(e) {
        this.showErrorDiscordAccountLinked()
      }
    }
  }

  async unlinkToDiscord() {
    const unlinkingAccount = `You are logged as ${this.discordCurrentUserIdentity}. You are going to unlink your Discord Account. Are you sure?`
    const confirmUnlinkAccount = await this.$bvModal.msgBoxConfirm(unlinkingAccount , {
      title: `Unlinking Discord Account`,
      okVariant: 'primary',
      okTitle: 'Unlink',
      centered: true
    })

    if (confirmUnlinkAccount) {
      await Account.disconnectToDiscord();
      
      this.showSuccessToast({
        title: 'Successfully unlinked!',
        message: 'You unlinked your Discord Account'
      })

      await this.getUser()
    }
  }

  showSuccessfullyDiscordAccountLinked() {
    this.showSuccessToast({
      title: 'Discord account linked',
      message: 'You successfully linked your Discord account!'
    })
  }

  showErrorDiscordAccountLinked() {
    this.showErrorToast({
      title: 'Discord link failed',
      message: 'An error occurred when trying to link your Discord account!'
    })
  }
}