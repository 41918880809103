














import { Component, Mixins } from 'vue-property-decorator'
import { IMixinConnections } from '@/types/interfaces'
import Discord from '@/services/Connections/Discord'

@Component({
  components: {
    ProfileHeader: () => import('@/components/Profile/ProfileHeader.vue'),
    ProfileAbout: () => import('@/components/Profile/ProfileAbout.vue'),
    ProfileCharacters: () => import('@/components/Profile/ProfileCharacters.vue')
  }
})
export default class Profile extends Mixins<IMixinConnections>(Discord) {
  async mounted() {
    await this.checkIfDiscordIsLinked()
  }
}
